<template lang="pug">
Auth(@loggedIn="onLoggedIn"
  :redirectWithLoginFailed="true")
  Header(:content="headerContent")
  div.wrap-send-inviter-events.f.fh
    ModuleSlotSelector(v-if="isReady"
      ref="slotSelector"
      :gcEvents="gcEvents"
      @setAvailableSlotsNum="setAvailableSlotsNum")
    div(@click="onSend").wrap-create-btn.f.fh
      div(:class="{'active': availableSlotsNum > 0}").create-btn-content.py12
        span.block.text-center.mr4 {{availableSlotsNum}}件の日時を送信する
  NowLoading(:show="showNowLoading")
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-send-inviter-events {
  width: 100%;
  // min-height: 100vh;
  .wrap-create-btn {
    z-index: 1;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 12px;
    .create-btn-content {
      width: $base_width_percent;
      max-width: $base_max_width_px;
      cursor: pointer;
      border-radius: 28px;
      filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));
      background: $secondary_text_color;
      &.active {
        background: $active_color;
      }
      span {
        color: #fff;
      }
    }
  }
}
</style>

<script>
import database from '@/database'
import { areIntervalsOverlapping, formatRFC3339 } from 'date-fns'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import Auth from '@/components/shared/Auth'
import Header from '@/components/shared/Header.vue'
import NowLoading from '@/components/shared/NowLoading'
import ModuleSlotSelector from '@/components/module/ModuleSlotSelector'
import { getGoogleCalendarEvents } from '@/services/google'
import { getMicrosoftCalendarEvents } from '@/services/microsoft'

export default {
  components: {
    Auth,
    Header,
    NowLoading,
    ModuleSlotSelector
  },
  data() {
    return {
      user: null,
      gcEvents: [],
      headerContent: {
        title: '候補日時の追加・削除',
        left: {
          icon: 'mdi-chevron-left',
          to: '/create-meeting'
        }
        // right: {
        //   label: '送信',
        //   color: '#4285f4',
        //   to: this.onSend
        // }
      },
      showNowLoading: false,
      isReady: false,
      availableSlotsNum: 0
    }
  },
  computed: {
    ...mapStateAuth(['accessToken', 'uid'])
  },
  methods: {
    async onLoggedIn() {
      this.showNowLoading = true

      this.user = await database.userCollection().findById(this.uid)

      // const response = await fetch(`${api}/getEventsInGoogleCalendar`, {
      //   method: 'POST',
      //   mode: 'cors',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'Accept': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     uid: this.uid
      //   })
      // })
      // let result = await response.json()
      // result = result.data
      // const self = this
      if (this.$route.params.generateSlotsWith === 'google') {
        const {
          data: { items: events }
        } = await getGoogleCalendarEvents({ accessToken: this.accessToken, uid: this.uid })
        if (events && events[0]) {
          console.log('onLoggedIn GG', { events })
          this.gcEvents = events.map((e) => {
            return {
              name: e.summary ? e.summary : ' ',
              start: e.start.dateTime ? e.start.dateTime : e.start.date,
              end: e.end.dateTime ? e.end.dateTime : e.end.date,
              isAllDayEvent: e.start.date ? true : false
            }
          })
        }
      } else if (this.$route.params.generateSlotsWith === 'outlook') {
        const {
          data: { value: events }
        } = await getMicrosoftCalendarEvents().catch(() => ({ value: [] }))
        if (events && events[0]) {
          console.log('onLoggedIn MS', { events })
          this.gcEvents = events.map((e) => {
            return {
              title: e.subject || ' ',
              start: e.start.dateTime ? new Date(e.start.dateTime) : new Date(e.start.date),
              end: e.end.dateTime ? new Date(e.end.dateTime) : new Date(e.end.date),
              isAllDayEvent: e.isAllDay,
              eventType: 'outlook'
            }
          })
        }
      }

      this.isReady = true
      this.showNowLoading = false
    },
    async onSend() {
      if (this.availableSlotsNum <= 0) {
        alert('日時が選択されていません')
        return
      }

      const go = confirm(`${this.availableSlotsNum}件の日時を送信します`)
      if (!go) return

      this.showNowLoading = true

      const slotSelector = this.$refs.slotSelector
      let candidateSlots = slotSelector.candidateSlots.map((slot) => {
        return { start: new Date(slot.start), end: new Date(slot.end) }
      })
      let availableSlots = slotSelector.slots.filter((slot) => {
        if (slot.isInitialSlot && slot.type === 'available_slot') return slot.active
        return slot.type === 'available_slot'
      })

      // candidateSlotsの中で選択されなかったものをeventにする
      let events = []
      for (let i = 0; i < candidateSlots.length; i++) {
        const isSameDate = (date1, date2) => {
          return date1.toISOString() === date2.toISOString()
        }
        const sameSlot = availableSlots.filter((slot) => {
          return (
            isSameDate(slot.start, candidateSlots[i].start) &&
            isSameDate(slot.end, candidateSlots[i].end)
          )
        })[0]
        // 同じスロットがなければそこは参加できないスロット = イベントがあるスロット
        if (!sameSlot) events.push(candidateSlots[i])
      }

      events = events.map((slot) => {
        return {
          start: formatRFC3339(slot.start),
          end: formatRFC3339(slot.end)
        }
      })
      const gcEvents = this.gcEvents
        .map((event) => {
          return { start: event.start, end: event.end }
        })
        .filter((event) => {
          // HOT FIX:
          // 入力されたavailableSlotsとかぶっているイベントはイベントとしてカウントしないようにする
          const aSlots = slotSelector.slots.filter((slot) => slot.type === 'available_slot')

          let eventIsOverlapping = false
          for (let i = 0; i < aSlots.length; i++) {
            if (
              areIntervalsOverlapping(
                { start: new Date(event.start), end: new Date(event.end) },
                { start: aSlots[i].start, end: aSlots[i].end }
              )
            ) {
              eventIsOverlapping = true
            }
          }
          return !eventIsOverlapping
        })

      if (gcEvents) events = events.concat(gcEvents)

      const resultAvailableSlots = slotSelector.slots
        .filter((slot) => slot.type === 'available_slot' && slot.active)
        .map((slot) => {
          return { start: formatRFC3339(slot.start), end: formatRFC3339(slot.end) }
        })

      const attendeeEvents = {
        events,
        availableSlots: resultAvailableSlots,
        createdBy: this.uid,
        email: this.user.email,
        authEmail: this.user.email,
        timeZone: Intl
          ? Intl.DateTimeFormat().resolvedOptions().timeZone
          : { offset: new Date().getTimezoneOffset() },
        createdAt: new Date()
      }

      await database
        .meetingCollection()
        .setAttendeeEvents(this.$route.params.meetingId, this.user.email, attendeeEvents)

      // eslint-disable-next-line
      this.$root.$emit('sendTrackingEventByClick', { 
        message: 'click_send_btn',
        option: {
          meetingId: this.$route.params.meetingId,
          candidateNum: this.availableSlotsNum
        }
      })

      this.$router.push(`/new-meeting/${this.$route.params.meetingId}`)

      this.showNowLoading = false
    },
    setAvailableSlotsNum(num) {
      this.availableSlotsNum = num
      // console.log('setAvailableSlotsNum', this.slotsNum)
    }
  }
}
</script>
